import styled from 'styled-components'

const Card = styled.div`
  border: 1px solid ${ props => props.theme.color.grey[2] };
  margin: 1.2em;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
`

const CardTitle = styled.h4`
  font-family: ${ props => props.theme.text.fontFamily };
  margin: 0;
  line-height: initial;
`

const CardSubtitle = styled.span`
  color: ${ props => props.theme.color.grey[4] };
  line-height: 1.2;
  padding: 0.8em 0;
`

export { Card, CardTitle, CardSubtitle }
