import styled from 'styled-components'
import { Card } from '../ui/layout/Cards'
import { devices } from '../../theme'

const FaqCard = styled(Card)`
  text-align: left;
  padding: 1.5em;
  width: 100%;
  position: relative;
  counter-reset: faq;

  h4,
  p {
    margin: 0;
  }

  h4 {
    color: ${ props => props.theme.color.grey[5] };
    padding-bottom: 1.2em;
    margin-bottom: 1.2em;
    border-bottom: 1px solid ${ props => props.theme.color.grey[2] };
  }

  p {
    color: ${ props => props.theme.color.grey[4] };
  }

  @media (${ devices.tablet }) {
    width: 100%;
  }

  a {
    color: ${ props => props.theme.color.primaryColor };

    :hover {
      text-decoration: underline;
    }
  }
`
const Counter = styled.div`
  position: absolute;
  left: -12px;
  background-color: ${ props => props.theme.color.accentColor };
  color: #fff;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
`

export { FaqCard, Counter }
