import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { rgba } from 'polished'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { irBlack as style } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import Carousel from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'

import SEO from '../../components/seo'
import { Wrapper, Container, Row, Column, Inner } from '../../components/ui/layout/Grid'
import Bg from '../../images/products/data_api_bg.svg'
import codeBg from '../../images/products/code_bg.svg'
import { SectionHeading } from '../../components/ui/Heading'
import { TopTitle } from '../../components/Hero/PageIntro'
import { ContentBox } from '../../components/ui/layout/ContentBox'
import theme, { devices } from '../../theme'
import { Info } from '../../components/ui/Icons'
import { InfoBox, Main, Intro } from '../../components/Products/Elements'
import CTA from '../../components/Products/CTA'
import Pricing from '../../components/Products/Pricing'
import { ApiPricing } from '../../components/Products/pricing-list'
import { FaqCard, Counter } from '../../components/Faq/FaqCard'
import Button from '../../components/ui/forms/Buttons'
import LinkOut from '../../components/ui/forms/LinkOut'

// Illustrations
import dataChecking from '../../images/products/data_checking.svg'
import dataDelivery from '../../images/products/data_delivery.svg'
import dataRealtime from '../../images/products/data_realtime.svg'

// Icons
import dataApiIcon from '../../images/products/data_api_icon.svg'
import marketIcon from '../../images/products/market_icon.svg'
import arrowRight from '../../images/assets/icons/arrow-right.svg'
import marketSymbols from '../../images/products/market_symbols.svg'
import assetsIcon from '../../images/products/assets_icon.svg'
import exchangesIcon from '../../images/products/exchanges_icon.svg'
import quotesIcon from '../../images/products/quotes_icon.svg'
import orderBooksIcon from '../../images/products/order_books_icon.svg'
import exchangeRatesIcon from '../../images/products/exchange_rates_icon.svg'
import ohlcvIcon from '../../images/products/ohlcv_bar_data_icon.svg'
import phpIcon from '../../images/products/php.svg'
import goIcon from '../../images/products/go.svg'
import pythonIcon from '../../images/products/python.svg'
import jsIcon from '../../images/products/js.svg'

const Window = styled.div`
  background-image: url(${ codeBg });
  background-repeat: no-repeat;
  background-position: right;
  background-color: ${ props => props.theme.color.primary[6] };
  width: 700px;
  border-radius: 12px;
  margin: 0 auto;
  box-shadow: 0 8px 30px -4px ${ props => rgba(props.theme.color.primary[6], 0.5) }, inset 0 -4px 0 ${ props => props.theme.color.accentColor }, inset 0 3px 0 ${ props => props.theme.color.primaryColor };

  pre {
    position: relative;
    top: -1em;
  }

  @media(${ devices.tablet }) {
    width: 100%;
  }

  @media(${ devices.mobile }) {
    background-image: none;
  }
`

const WindowControls = styled.div`
  margin-left: 1em;

  i {
    font-size: 0;
    display: inline-block;
    position: relative;
    width: 14px;
    height: 14px;
    background-color: #ff003f;
    border-radius: 20px;
    margin-left: 2em;

    &::before,
    &::after {
      content: '';
      width: 14px;
      height: 14px;
      position: absolute;
      border-radius: 20px;
      background-color: white;
    }

    &::before {
      background-color: #ffc600;
      left: 25px;
    }

    &::after {
      background-color: ${ props => props.theme.color.accentColor };
      left: 50px;
    }
  }
`

const codeString = `
  [
    {
      "added": 1483228800,
      "color": {
        "b": 73,
        "g": 163,
        "r": 230
      },
      "market_cap": 203291395158,
      "market_data_time": 1561707540,
      "name": "Bitcoin",
      "price_btc": 1.0,
      "price_usd": 11430.4253557,
      "slug": "btc",
      "symbol_name": "bitcoin",
      "volume_usd": 39883459887
    },
  ]
`

const ourData = [
  {
    title: 'Market Symbols',
    description: 'Labeled in a unified way to make integration seamless',
    icon: marketSymbols
  },
  {
    title: 'Assets',
    description: '2200+ assets tracked from our exchanges to provide a complete market picture',
    icon: assetsIcon
  },
  {
    title: 'Exchanges',
    description: '46+ exchanges even those who have shutdown',
    icon: exchangesIcon
  },
  {
    title: 'Quotes',
    description: 'The crypto pairs price second by second',
    icon: quotesIcon
  },
  {
    title: 'Order Books',
    description: 'Full order book data available',
    icon: orderBooksIcon
  },
  {
    title: 'Exchange Rates',
    description: 'The assets current price on listed exchanges',
    icon: exchangeRatesIcon
  },
  {
    title: 'OHLCV Bar Data',
    description: 'We provide all the data for Open, High, Low, Close, and Volume',
    icon: ohlcvIcon
  },
]

const faqContent = [
  {
    title: 'What is counted as a request on the REST API?',
    content: 'Any request made to the server via the API will count towards a request.'
  },
  {
    title: 'How long does it take to integrate Investabit?',
    content: 'With our SDKs it can take just a few minutes to integrate with your systems or service. Depending on the needs, we offer custom solutions.'
  },
  {
    title: 'What exchanges and assets are integrated?',
    content: 'You can see a list of the assets and exchanges either by querying the API or visiting this page.'
  },
  {
    title: 'What are the restrictions on usage of real-time protocols?',
    content: 'Depending on the product you select, there are restrictions for what type of data you have access to in real time. For example with Startup you will receive only the Trade data, whereas with the Professional offering you have Trades, Quotes and Orderbooks.'
  },
]

const OurContentBox = styled(ContentBox)`
  box-shadow: ${ props => props.active ? `0 0 0 3px ${ props.theme.color.primaryColor }` : 'none' };
  width: ${ props => props.cardWidth }px;
  height: 100%;
  margin: 0.7em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-tap-highlight-color: transparent;
  border-color: ${ props => props.active && 'transparent' };
  transition: all 0.25s ease;
  counter-increment: boxCount;

  img {
    max-width: 200px;
    margin: 0 auto;
    margin-bottom: 0.5em;
  }

  h2 {
    font-family: ${ props => props.theme.text.fontFamily };
    position: relative;

    &::before {
      content: counter(boxCount);
      position: absolute;
      left: -0.1em;
      top: -0.6em;
      font-size: 82px;
      font-weight: 700;
      color: ${ props => rgba('#000', 0.05) };
    }
  }

  p {
    font-size: 1.8rem;
  }

  :hover,
  :active {
    cursor: grab;
    user-select: none;
  }

`

const BoxDesc = styled.div`
  height: 140px;
  padding-top: 2em;
  border-top: 1px solid ${ props => props.theme.color.grey[1] };
`

const Arrow = styled.button`
  background-image: url(${ arrowRight });
  background-repeat: no-repeat;
  background-size: 42px;
  width: 42px;
  height: 42px;
  transform: ${ props => props.dir === 'right' ? 'rotate(0)' : 'rotate(180deg)' };
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  margin-left: ${ props => props.dir === 'left' && '-3em' };
  margin-bottom: ${ props => props.dir === 'left' && '-1.5px' };
  left: 50%;
  bottom: 2em;
  z-index: 100;
  outline: 0;
  opacity: 0.7;
  transition: opacity 0.25s ease;

  :hover {
    opacity: 1;
  }
`

const SDKLink = styled(LinkOut)`
  padding: 2.5em;
  transition: opacity 0.25s ease;

  :hover {
    opacity: 0.7;
  }
`

const DataApi = () => {
  return (
    <>
      <SEO
        title="Cryptocurrency Exchanges Under a Single API"
        description="Investabit is a platform which provides fast, reliable and unified data APIs to cryptocurrency markets"
      />
      <Container size="md">
        <Row mb="4em">
          <Column>
            <Intro>
              <img
                src={dataApiIcon}
                alt="Cryptocurrency exchange data"
              />
              <h1>Cryptocurrency Exchanges Under a <strong>Single API</strong></h1>
              <p
                css={`
                  opacity: 0.7;
                `}
              >
                Investabit is a platform which provides fast, reliable and unified data APIs to cryptocurrency markets
              </p>
              <Button
                to="/account/register"
                appearance="primary"
              >
                Get a Free API Key
              </Button>
              <Button
                as="a"
                appearance="secondary"
                href="#pricing"
              >
                See Pricing
              </Button>
            </Intro>
          </Column>
        </Row>
      </Container>
      <Main
        bgImage={Bg}
        elHeight={730}
      >
        <Window>
          <WindowControls>
            <i>window buttons</i>
          </WindowControls>
          <SyntaxHighlighter
            language="json"
            style={style}
            customStyle={{
              background: 'none',
              padding: '0 2em',
              paddingBottom: '1.5em',
              fontSize: '1.4rem'
            }}
          >
            {codeString}
          </SyntaxHighlighter>
        </Window>
      </Main>
      <Container size="md">
        <Row dir="column">
          <Column>
            <img
              src={marketIcon}
              alt="Market data provider icon"
              css={`
                width: 84px;
                margin: 1em auto;
              `}
            />
          </Column>
          <Column>
            <SectionHeading>
              <h2>We are the only provider for all of your cryptocurrency market data needs</h2>
            </SectionHeading>
          </Column>
        </Row>
      </Container>
      <Container>
        <Row
          mt="6em"
          mb="6em"
          grid={1 / 2}
          gutter={1}
          align="center"
        >
          <Column>
            <ContentBox
              bg={theme.color.primaryColor}
              bgPattern={true}
            >
              <p>All Investabit data has been standardized. Ticker symbol details and asset codes are triple-checked by our staff, and algorithmic functions to ensure the very highest quality and consistency</p>
            </ContentBox>
          </Column>
          <Column>
            <Inner>
              <img
                src={dataChecking}
                alt="Data checking"
              />
            </Inner>
          </Column>
        </Row>
      </Container>
      <Container size="md">
        <Row mb="0">
          <Column>
            <SectionHeading>
              <h3>Want to get started? Look through the SDKs such as Javascript, Python, PHP and Go.</h3>
            </SectionHeading>
          </Column>
        </Row>
        <Row
          mt="0"
          grid={1 / 4}
        >
          <Column>
            <SDKLink to="https://github.com/Investabit/investabit-go-sdk">
              <img
                src={goIcon}
                alt="GitHub Go SDK"
              />
            </SDKLink>
          </Column>
          <Column>
            <SDKLink to="https://github.com/Investabit/investabit-js-sdk">
              <img
                src={jsIcon}
                alt="GitHub JavaScript SDK"
              />
            </SDKLink>
          </Column>
          <Column>
            <SDKLink to="https://github.com/Investabit/investabit-python-sdk">
              <img
                src={pythonIcon}
                alt="GitHub Python SDK"
              />
            </SDKLink>
          </Column>
          <Column>
            <SDKLink to="https://github.com/Investabit/investabit-php-sdk">
              <img
                src={phpIcon}
                alt="GitHub PHP SDK"
              />
            </SDKLink>
          </Column>
          <LinkOut
            to="https://investabit.docs.apiary.io/"
            style={{ marginTop: '1.2em' }}
            css="text-align:center; margin:auto; margin-bottom: 2em;"
          >
            API Documentation
          </LinkOut>
        </Row>
      </Container>
      <Wrapper
        css={`
            border-top: 1px solid ${ theme.color.grey[1] };
          `}
      >
        <Container>
          <Row mt="4em">
            <Column align="center">
              <SectionHeading>
                <TopTitle>Features</TopTitle>
                <h2>Data Delivery</h2>
              </SectionHeading>
            </Column>
          </Row>
          <Row
            grid={1 / 2}
            gutter={1}
            align="center"
          >
            <Column orderBy={1}>
              <ContentBox
                bg={theme.color.accentColor}
                bgPattern={true}
              >
                <p>Our data is delivered to you on-demand using well-documented and simple GRPC, Websockets, FIX and HTTP RESTful API in JSON, XML or CSV formats</p>
              </ContentBox>
            </Column>
            <Column orderBy={0}>
              <Inner>
                <img
                  src={dataDelivery}
                  alt="Data delivery"
                />
              </Inner>
            </Column>
          </Row>
          <Row
            grid={1 / 2}
            gutter={1}
            align="center"
          >
            <Column>
              <Inner>
                <img
                  src={dataRealtime}
                  alt="Real time data delivery"
                />
              </Inner>
            </Column>
            <Column>
              <ContentBox
                bg="#FFC456"
                bgPattern={true}
              >
                <p>If you require real-time market streaming data, we have access through our GRPC or WebSocket solution for your second by second needs</p>
              </ContentBox>
            </Column>
          </Row>
        </Container>
      </Wrapper>
      <Wrapper
        bgColor={theme.color.grey[0]}
        css={`
            padding-bottom: 6em;
        `}
      >
        <Container>
          <Row
            mt="4em"
            mb="4em"
          >
            <Column align="center">
              <SectionHeading>
                <TopTitle>Data Types</TopTitle>
                <h2>Our Data</h2>
              </SectionHeading>
            </Column>
          </Row>
        </Container>
        <Carousel
          slidesPerPage={4}
          centered
          keepDirectionWhenDragging
          itemWidth={420}
          arrowRight={<Arrow dir="right" />}
          arrowLeft={<Arrow dir="left" />}
          addArrowClickHandler
          css={'counter-reset: boxCount;'}
        >
          {ourData.map((item, idx) =>
            <OurContentBox
              key={idx}
              cardWidth={420}
            >
              <img
                src={item.icon}
                alt={`${ item.title } icon`}
              />
              <BoxDesc>
                <h2>{item.title}</h2>
                <p>{item.description}</p>
              </BoxDesc>
            </OurContentBox>
          )}
        </Carousel>
      </Wrapper>
      <Wrapper bgColor={theme.color.grey[6]}>
        <Container size="lg">
          <Row
            mt="3em"
            mb="0"
          >
            <Column>
              <SectionHeading inverted>
                <h2>Historical Data</h2>
              </SectionHeading>
            </Column>
          </Row>
          <Row
            dir="column"
            mt="4em"
            mb="4em"
          >
            <Column>
              <InfoBox dark>
                <div>
                  <Info />
                </div>
                <p>Looking for historical data? We have a solution for you, we provide access to our historical market data through {' '}
                  <Link
                    css={'color:orange'}
                    to="/products/data-sales/"
                  >Investabit Data Sales</Link>.</p>
              </InfoBox>
            </Column>
            <Column>
              <InfoBox dark>
                <div>
                  <Info />
                </div>
                <p>Our current database holds more than 40 TB of raw and preprocessed market data available through the self service portal.</p>
              </InfoBox>
            </Column>
          </Row>
        </Container>
      </Wrapper>
      <Container>
        <CTA
          title="Take it for a Test Drive"
          paragraph="Get a free API key and get started with one of our SDKs."
          button={{
            title: 'Get a Free API Key',
            to: '/account/register'
          }}
        />
      </Container>
      <Wrapper bgColor={theme.color.grey[0]}>
        <Container id="pricing">
          <Row
            mt="4em"
            mb="0em"
          >
            <Column>
              <SectionHeading>
                <TopTitle>Different tiers for different needs</TopTitle>
                <h2>Pricing</h2>
              </SectionHeading>
            </Column>
          </Row>
        </Container>
        <Pricing data={ApiPricing} />
      </Wrapper>
      <Container>
        <Row
          mt="4em"
        >
          <Column>
            <SectionHeading>
              <h2>Frequently Asked Questions</h2>
            </SectionHeading>
          </Column>
        </Row>
        {faqContent.map((faq, idx) =>
          <FaqCard key={faq.title + idx}>
            <Counter>{idx + 1}</Counter>
            <h4>{faq.title}</h4>
            <p>{faq.content}</p>
          </FaqCard>
        )}
      </Container>
    </>
  )
}

export default DataApi
